import styled from "styled-components";

export const HeaderFoundationContainer = styled.header`
  position: relative;
  .logoHome {
    position: relative;
    z-index: 999;
    img {
      /* display: flex; */
      margin: 30px;
      max-width: 100px;
    }
  }

  .main-container {
    position: relative;
    display: block;

    margin: 0 auto;
    padding: 0 15px;

    width: 100%;
    max-width: 1118px;

    text-align: center;

    .holder-title {
      margin: 0 auto;
      padding-top: 100px;
      padding-bottom: 150px;
      position: relative;
      display: flex;
      flex-direction: column;
      opacity: 1;

      @media (max-width: 650px) {
        padding-top: 45px;
        padding-bottom: 40px;
      }

      &:before {
        content: "";
        background-image: url("/assets/images/bg-arrows-right.svg");
        background-repeat: no-repeat;
        width: 380px;
        height: 470px;
        position: absolute;
        left: -105px;
        top: -105px;
        z-index: -1;
        opacity: 0.3;
        @media (max-width: 480px) {
          width: 260px;
          height: 322px;
          left: -45px;
          bottom: 30px;
          top: unset;
          background-size: 100%;
        }
      }

      &:after {
        content: "";
        background-image: url("/assets/images/bg-arrows-left.svg");
        background-repeat: no-repeat;
        width: 380px;
        height: 470px;
        position: absolute;
        right: -90px;
        top: unset;
        bottom: -100px;
        z-index: -1;
        opacity: 0.3;
        @media (max-width: 480px) {
          width: 260px;
          height: 322px;
          background-size: 100%;
          right: -50px;
          top: unset;
          bottom: -235px;
        }
      }

      h1 {
        font-weight: 800;
        font-size: clamp(32px, 8vw, 64px);
        text-align: center;
        margin-bottom: 10px;
        transform: translateY(0px);
        transition: all 600ms ease-out;
      }
      p {
        margin: 0 auto;
        max-width: 600px;
        font-family: "Roboto";
        font-size: clamp(14px, 8vw, 18px);
        margin-bottom: 28px;
        text-align: center;
      }
    }
  }
`;
