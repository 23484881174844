import HeaderFoundation from "components/HeaderFoundation/HeaderFoundation";
import BodyFoundation from "components/BodyFoundation/BodyFoundation";
import styled from "styled-components";

const FoudationContainer = styled.section`
  background-color: black;
  color: white;
`;

const Foundation = () => {
  return (
    <FoudationContainer>
      <HeaderFoundation />
      <BodyFoundation />
    </FoudationContainer>
  )
}

export default Foundation
