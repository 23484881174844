import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import BG from "../../assets/img/header.jpg";
import LOGO from "../../assets/img/logo.png";
import LogoFoundation from '../../assets/img/fundacion_rimas.svg';

import Fade from "react-reveal/Fade";
// import Slide from "react-reveal/Slide";

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 350px;
  height: 31.7vw;
  max-height: 617px;
  position: relative;
  z-index: 10;

  border-top: 3px solid #3583c3;

  @media (max-width: 950px) {
    max-height: 350px;
    min-height: 350px;
  }

  .bg {
    background: url(${BG}) top center no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;

    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
  }

  .logo {
    position: relative;
    z-index: 1;

    min-width: 250px;
    max-width: 600px;
    width: 35.3vw;

    @media (max-width: 950px) {
      width: 250px;
    }
  }

  .logo-foundation {
    position: absolute;
    top: 40px;
    left: 40px;
    z-index: 100;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    object-fit: cover;
  }
`;

function Header() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  return (
    <HeaderContainer>
      <Link to="rimas-foundation">
        <img 
        src={LogoFoundation}
        className="logo-foundation"
        alt="Rimas Foundation"
        />
      </Link>
      <Fade bottom delay={500}>
        <img
          src={LOGO}
          alt="RIMAS"
          className="logo"
          style={{ marginTop: `-${scrollPosition}px` }}
        />
      </Fade>
      <Fade duration={500}>
        <div className="bg" />
      </Fade>
    </HeaderContainer>
  );
}

export default Header;
