import React from "react";
import styled from "styled-components";
import { FaFacebookF, FaYoutube, FaInstagram } from "react-icons/fa";

const Icon = styled.a`
  color: #fff;

  svg {
    transition: all 0.4s ease-in-out;
  }

  &:hover {
    svg {
      transform: rotate(-10deg) scale(1.5);
    }
  }
`;

function SocialIcons(props) {
  const social = props.data;
  const addMargin = props.addMargin || false;

  return (
    <div className={`socialIcons ${addMargin ? "addMargin" : ""}`}>
      {social.map((response, index) => {
        return (
          <Icon
            href={response.link}
            rel="noopener noreferrer"
            key={`s_${index}`}
            target="_blank"
          >
            {response.type === "instagram" ? <FaInstagram /> : null}
            {response.type === "youtube" ? <FaYoutube /> : null}
            {response.type === "facebook" ? <FaFacebookF /> : null}
          </Icon>
        );
      })}
    </div>
  );
}

export default SocialIcons;
