import React from "react";

import { Card, CardInstagram } from "./styles";

function ArtistCard(props) {
  const content = props.data;

  let artist = (
    <Card href={content.link} target="_blank">
      <div className="overlay">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="897"
          height="1057"
          viewBox="0 0 897 1057"
        >
          <path
            id="Rectangle_1_copy_3"
            data-name="Rectangle 1 copy 3"
            className="cls-1"
            d="M1.993,1.987L895,537.085h0L3.983,1056h0L1.993,1.987h0Z"
          />
        </svg>
      </div>
      <div className="name">{content.name}</div>
      <img src={content.image} alt={content.name} />
    </Card>
  );

  if (content.links) {
    artist = (
      <CardInstagram>
        <div className="overlay">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="897"
            height="1057"
            viewBox="0 0 897 1057"
          >
            <path
              id="Rectangle_1_copy_3"
              data-name="Rectangle 1 copy 3"
              className="cls-1"
              d="M1.993,1.987L895,537.085h0L3.983,1056h0L1.993,1.987h0Z"
            />
          </svg>
        </div>
        <div className="name">{content.name}</div>
        <img src={content.image} alt={content.name} />

        <a
          target="_blank"
          rel="noopener noreferrer"
          href={content.links[1].link}
          className="top"
        >
          <span className="name">{content.links[1].name}</span>
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href={content.links[0].link}
          className="bottom"
        >
          <span className="name">{content.links[0].name}</span>
        </a>
      </CardInstagram>
    );
  }

  return <div>{artist}</div>;
}

export default ArtistCard;
