import styled from "styled-components";

export const BodyFoundationContainer = styled.div`
  .main-container {
    position: relative;
    display: block;

    margin: 0 auto;
    padding: 0 15px;
    
    width: 100%;
    max-width: 750px;

    img {
      width: 100%;
    } 

    @media (max-width: 650px) {
      padding: 0 25px;

    }

  }

  .text-image {
    font-family: "Roboto";
    font-size: 16px;
    margin: 20px 0 30px;
  }

  .simple-rich-text {
    padding: 30px 0;

    h3 {
      font-size: 18px;
      font-weight: 800;
      border-bottom: 1px solid #ed1b24;
      padding-bottom: 5px;
      display: inline-block;
      margin-bottom: 20px;
    }
    p {
      font-family: "Roboto";
      font-size: 16px;
      margin: 0 0 20px;
    }
    
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;

      margin-bottom: 40px;

      @media (max-width: 650px) {
        grid-template-columns: 1fr;
        gap: 0;
        li {
          padding: 0;
        }
      }

      li {
        font-family: "Roboto";
        font-size: 15px;
        padding: 16px 0;
        /* background-color: #333; */
        /* border-radius: 10px; */
        font-weight: 200;

        ::before {
          display: block;
          color: #fff;
          content: url("data:image/svg+xml;charset=UTF-8, <svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='red' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path></svg>");
          background-size: 20px 20px;
          height: 20px;
          width: 20px;
          padding-bottom: 10px;
        } 
      }
    }
  }

  .swiper-pagination > .swiper-pagination-bullet {
    opacity: 1;
    border: white solid 1px;
    background-color: transparent;
  }
  .swiper-pagination > .swiper-pagination-bullet-active {
    background-color: white;
  }
`;