import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import json from "./json/data.json";

import Footer from "components/Footer/Footer";
import LangSwitcher from 'components/LangSwitcher/LangSwitcher';

import Home from './pages/Home';
import Foundation from './pages/Foundation';
// import PrivacyPolicy from './pages/PrivacyPolicy';

import GlobalStyles from "./styles/GlobalStyles";
// import useScript from 'hooks/useScript';

const Loader = () => (
  <div>
    <div className="mt-5">loading...</div>
  </div>
);

function App() {
  const data = json;

  // useScript('https://ui.upcp.wirewheel.io/extensions/upcp-sdk-0.8.3.min.js');
  // useScript('https://ui.upcp.wirewheel.io/extensions/upcp-sdk-bridge-1.3.0.min.js');
  return (
    <>
    <Suspense fallback={<Loader />}>
      <LangSwitcher />
      <Routes>
        <Route path="/" exact element={<Home data={data} />} />
        <Route path="/rimas-foundation" exact element={<Foundation/>} />
        {/* <Route path="/privacy-policy" exact element={<PrivacyPolicy/>} /> */}
      </Routes>
      <Footer data={data.social_media} />

      <GlobalStyles />
    </Suspense>
    </>
  );
}

export default App;
