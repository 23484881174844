import React from "react";
import {  Pagination } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { useTranslation, Trans } from 'react-i18next';
import Fade from "react-reveal/Fade";

import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
import { BodyFoundationContainer } from "./styles";

const BodyFoundation = () => {
  const { t } = useTranslation();

  return (
    <BodyFoundationContainer>
      <Fade bottom duration={600}>
      <div className="main-container">
        <Swiper modules={[ Pagination ]} pagination={{ clickable: true }}>
          <SwiperSlide><img src="/assets/images/foundation-06.jpg" alt="Fundación Rimas" /></SwiperSlide>
          <SwiperSlide><img src="/assets/images/foundation-01.jpg" alt="Fundación Rimas" /></SwiperSlide>
          <SwiperSlide><img src="/assets/images/foundation-02.jpg" alt="Fundación Rimas" /></SwiperSlide>
          <SwiperSlide><img src="/assets/images/foundation-03.jpg" alt="Fundación Rimas" /></SwiperSlide>
          <SwiperSlide><img src="/assets/images/foundation-04.jpg" alt="Fundación Rimas" /></SwiperSlide>
          <SwiperSlide><img src="/assets/images/foundation-05.jpg" alt="Fundación Rimas" /></SwiperSlide>
        </Swiper>
        <p className="text-image">
          <Trans i18nKey="foundation.textImage" t={t}>Promovemos el bienestar, la educación y el desarrollo de habilidades sociales, talento y las capacidades de liderazgo para un mejor futuro.</Trans>
        </p>

        <section className="simple-rich-text">
          <h3><Trans i18nKey="foundation.mission" t={t}>Mission</Trans></h3>
          {t('foundation.missionTexts', { returnObjects: true }).map(item => (
            <p key={item.id}>{item.text}</p>
          ))}
          <p></p>
          <h3><Trans i18nKey="foundation.vision" t={t}>Title</Trans></h3>
          {t('foundation.visionTexts', { returnObjects: true }).map(item => (
            <p key={item.id}>{item.text}</p>
          ))}
          <p></p>
          <h3><Trans i18nKey="foundation.social" t={t}>Impacto social:</Trans></h3>
          <p><Trans i18nKey="foundation.projects" t={t}>Proyectos en desarrollo:</Trans></p>
          <ul>
          {t('foundation.socialTexts', { returnObjects: true }).map(item => (
            <li key={item.id}>{item.text}</li>
          ))}
          </ul>

        </section>
      </div>
      </Fade>
    </BodyFoundationContainer>
  );
};

export default BodyFoundation;
