import React from "react";

import ArtistCard from "../ArtistCard/ArtistCard";

function Artiststs(props) {
  const content = props.data;

  // function shuffleArray(array) {
  //   let i = array.length - 1;
  //   for (; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     const temp = array[i];
  //     array[i] = array[j];
  //     array[j] = temp;
  //   }
  //   return array;
  // }

  return (
    <div style={{ zIndex: 10, position: "relative" }}>
      {/* {shuffleArray(content).map((response, index) => { */}
      {content.map((response, index) => {
        return <ArtistCard key={index} data={response} />;
      })}
    </div>
  );
}

export default Artiststs;
