import React from "react";
import styled from "styled-components";

import Logo from "../../assets/img/logo.png";
import SocialIcons from "../SocialIcons/SocialIcons";
import FooterNav from "components/FooterNav/FooterNav";

import triangleBottomLeftBlue from "../../assets/img/triangleBottomLeftBlue.png";
import triangleBottomLeftRed from "../../assets/img/triangleBottomLeftRed.png";
import triangleBottomRightBlue from "../../assets/img/triangleBottomRightBlue.png";
import triangleBottomRightRed from "../../assets/img/triangleBottomRightRed.png";

const Container = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  position: relative;

  background: #323232;
  width: 100%;
  height: 225px;

  img {
    pointer-events: none;
  }

  .left,
  .right {
    position: absolute;
  }

  .left {
    left: 0;

    @media (max-width: 1100px) {
      left: -150px;
    }
  }

  .leftBlue {
    z-index: 1;
    top: -110px;

    @media (max-width: 730px) {
      top: -50px;
      left: -160px;
    }
  }

  .leftRed {
    top: -280px;

    @media (max-width: 730px) {
      left: -310px;
      top: -300px;
    }
  }

  .right {
    right: 0;

    @media (max-width: 1100px) {
      right: -150px;
    }
  }

  .rightBlue {
    top: -270px;

    @media (max-width: 730px) {
      right: -250px;
      top: -310px;
    }
  }

  .rightRed {
    z-index: 1;
    top: -50px;
  }

  .content {
    img {
      width: 145px;
      margin-bottom: 20px;
    }
  }

  .socialIcons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a:first-child {
      margin-left: -4px;
    }
  }
`;

function Footer(props) {
  const social = props.data;
  return (
    <Container>
      <img
        src={triangleBottomLeftBlue}
        className="left leftBlue"
        alt="Triangle"
      />
      <img
        src={triangleBottomLeftRed}
        className="left leftRed"
        alt="Triangle"
      />
      <img
        src={triangleBottomRightBlue}
        className="right rightBlue"
        alt="Triangle"
      />
      <img
        src={triangleBottomRightRed}
        className="right rightRed"
        alt="Triangle"
      />

      <div className="content">
        <img src={Logo} alt="RIMAS" />

        <SocialIcons data={social} />
      </div>
      <FooterNav />
    </Container>
  );
}

export default Footer;
