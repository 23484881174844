import { useState, useEffect, useCallback } from "react";

export const useScrollY = () => {
  const posLeftInitial = -200;
  const posRightInitial = -230;

  const [posLeft, setScrollPositionLeft] = useState(posLeftInitial);
  const [posRight, setScrollPositionRight] = useState(posRightInitial);

  const handleScroll = useCallback((e) => {
    const window = e.currentTarget;
    const position = window.pageYOffset;

    if(position < 1000) {
      setScrollPositionLeft(posLeftInitial - position);
    }
    setScrollPositionRight(posRightInitial + position / 2);

  },[posLeftInitial, posRightInitial]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return [posLeft, posRight];
};
