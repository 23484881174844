import styled from 'styled-components';
import optOut from '../../assets/img/opt-out.svg';

const NavContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;

  margin: 10px auto;
  padding: 20px;
  a {
    color: white;
    font-weight: 200;
    font-size: 12px;
    margin: 0;
  }
  .privacyPolicy{
    display: flex;
    align-items: center;
  }
  .privacyChoices {
    display: flex;
    align-items: center;
    margin-left: 20px;
    a {
      margin-right: 5px;
    }
  }
  .iconOptOut {
    height: 14px;
  }
`;

const FooterNav = () => {
  return (
    <NavContainer>
      <div className="privacyPolicy">
        <a href="https://rimasmusic.com/trust-center" className="footer-link">
          Trust Center
        </a>
      </div>
      <div className="privacyChoices">
        <a href="https://rimasmusic.com/your-privacy-choices" className="footer-link">
          Your Privacy Choices
        </a>
        <img className="iconOptOut" src={optOut} alt="yourPrivacyChoices" />
      </div>
    </NavContainer>
  );
};

export default FooterNav;
