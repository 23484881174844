import React from "react";
import { Link } from "react-router-dom";

import { useTranslation, Trans } from 'react-i18next';
import Fade from "react-reveal/Fade";

import { HeaderFoundationContainer } from "./styles";

import LOGO from "assets/img/logo.png";

const HeaderFoundation = () => {
  const { t } = useTranslation();

  return (
    <HeaderFoundationContainer>
      <Link to="/" className="logoHome" >
        <img src={LOGO} alt="Rimas" />
      </Link>
      <div className="main-container">
        <Fade bottom delay={200}>
          <div className="holder-title">
            <h1><Trans i18nKey="foundation.title" t={t}>Fundación Rimas</Trans></h1>
            <p>
              <Trans i18nKey="foundation.description" t={t}>Description</Trans>
            </p>
          </div>
        </Fade>
      </div>
    </HeaderFoundationContainer>
  );
};

export default HeaderFoundation;
