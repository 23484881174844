import { createGlobalStyle } from "styled-components";

import GothamBlack from '../assets/fonts/gotham-black-webfont.ttf';

export default createGlobalStyle`

  @font-face {
    font-family: 'Gotham Black';
    src: local('Gotham Black'), local('GothamBlack'),
    url(${GothamBlack}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  .addMargin a {
    margin: 0 10px;
    
    height: 20px;
    width: 20px;
    display: inline-block;

    @media (max-width: 1100px) {
      margin: 0 25px;
    }
  }

  .socialStripe {
    background: #323232;
    padding: 30px 0;
    text-align: center;
    margin-top: 30px;
  }

  *, html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Gotham Black';
    font-weight: 400;
    text-decoration: none;
    outline: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    list-style: none;
  }

  button, input {
    font-family: 'Montserrat';
    font-weight: 400;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  body {
    overflow-x: hidden;
  }

  :root {
  }
`;
