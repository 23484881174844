// import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from 'react-i18next';

import { useScrollY } from '../../hooks/useScrollY'

import { TextContainer } from "./styles";

import triangleTopRight from "../../assets/img/triangleTopRight.png";
import triangleTopLeft from "../../assets/img/triangleTopLeft.png";

import SocialIcons from "../SocialIcons/SocialIcons";

import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";

function Text({ data:content, social }) {

  const { t } = useTranslation();

  const [ posLeft, posRight] = useScrollY();

  return (
    <TextContainer>
      <Slide right delay={400}>
        <img
          src={triangleTopRight}
          alt="triangle"
          className="triangleRight"
          style={{ bottom: `${posRight}px` }}
        />
      </Slide>

      <Slide left delay={900}>
        <img
          src={triangleTopLeft}
          alt="triangle"
          className="triangleLeft"
          style={{ bottom: `${posLeft}px` }}
        />
      </Slide>

      <Fade bottom>
        <div className="text">
          <span className="strong"><Trans i18nKey="home.welcome" t={t}></Trans></span> 
          <Trans i18nKey="home.title" t={t}>Description</Trans>
        </div>
      </Fade>

      <Fade bottom>
        <div className="socialStripe">
          <SocialIcons addMargin={true} data={social} />
        </div>
      </Fade>
    </TextContainer>
  );
}

export default Text;
