import styled from "styled-components";

export const TextContainer = styled.div`
  text-align: center;
  position: relative;

  padding-top: 30px;

  img {
    position: absolute;
  }

  .triangleLeft {
    left: 0;
    /* bottom: -270px; */
    /* top: -8px; */
    pointer-events: none;

    z-index: 1;

    @media (max-width: 1740px) {
      top: initial;
      bottom: -272px;
    }

    @media (max-width: 1500px) {
      left: -158px;
    }
  }

  .triangleRight {
    right: 0;
    /* bottom: -270px; */
    /* top: -25px; */
    pointer-events: none;

    z-index: 1;

    @media (max-width: 1740px) {
      top: initial;
      bottom: -294px;
    }

    @media (max-width: 1500px) {
      right: -200px;
    }
  }

  .text {
    max-width: 1000px;

    width: 100%;
    margin: auto;
    padding: 40px;

    font-family: "Roboto";
    font-size: 18px;
    line-height: 28px;
    color: #383838;

    @media (max-width: 1630px) {
      padding-left: 50px;
      padding-right: 50px;
    }

    @media (max-width: 1139px) {
      font-size: 16px;
      line-height: 24px;
    }

    strong {
      font-weight: 700;
      font-family: "Roboto";
    }
  }

  .text + .text {
    margin-top: 25px;
  }
`;
