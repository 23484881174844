import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// https://react.i18next.com/guides/quick-start
// https://react.i18next.com/latest/using-with-hooks

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lang: 'es',
    supportedLngs: [ 'en', 'es'],
    fallbackLng: 'es',
    debug: false,

    react: {
      useSuspense: true,
    },

    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;