import { useTranslation } from 'react-i18next';
import styled, { css } from "styled-components";

const LangsContainer = styled.div`
  display: flex;

  position: absolute;
  right: 30px;
  top: 30px;

  z-index: 990;
`;

const ActiveLangButton = css`
  font-weight: 800;
  border-bottom: 1px solid #ed1b24;
  padding-bottom: 8px;
`;

const LangButton = styled.button`
  color: white;
  padding-bottom: 9px;
  margin-left: 10px;
  cursor: pointer;
  background: none;
  border: none;

  ${ props => props.active && ActiveLangButton}
`;

const LangSwitcher = () => {

  const { i18n } = useTranslation();

  const changeLang = () => {
    if(i18n.language === 'es') {
      i18n.changeLanguage('en');
    } else{
      i18n.changeLanguage('es');
    }
  }

  return (
    <LangsContainer>
      <LangButton active={i18n.language === 'es'} onClick={changeLang}>
        Esp
      </LangButton>
      <LangButton active={i18n.language === 'en'} onClick={changeLang}>
        Eng
      </LangButton>
    </LangsContainer>
  )
}

export default LangSwitcher
