
import Header from "components/Header/Header";
import Text from "components/Text/Text";
import Artiststs from "components/Artiststs/Artiststs";

function Home({data}) {
  

  return (
    <section className="Home">
      <Header />
      <Text data={data.content} social={data.social_media} />
      <Artiststs data={data.artists} />

    </section>
  );
}

export default Home;