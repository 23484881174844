import styled from "styled-components";

import Triangle01 from "../../assets/img/01.png";
import Triangle02 from "../../assets/img/02.png";

export const CardInstagram = styled.div`
  width: 25%;
  height: 25vw;

  display: block;
  float: left;
  position: relative;
  overflow: hidden;

  cursor: pointer;

  .top,
  .bottom {
    transition: all 0.6s ease-in-out;
  }

  .top {
    position: absolute;
    top: 0;
    right: -100%;

    background: url(${Triangle01}) top right no-repeat;

    width: 186px;
    height: 147px;

    .name {
      opacity: 1 !important;

      top: 23px;
      left: auto;
      right: 0;

      padding: 0;
      margin: 0;

      width: auto;

      transform: translate(0, 0);
    }
  }

  .bottom {
    position: absolute;
    bottom: 0;
    left: -100%;

    background: url(${Triangle02}) top right no-repeat;

    width: 186px;
    height: 147px;

    .name {
      opacity: 1 !important;

      top: 95px;
      left: 20px;
      transform: translate(0, 0);

      padding: 0;
      margin: 0;

      width: auto;
    }
  }

  .name {
    position: absolute;

    top: 50%;
    left: 0;
    transform: translate(0, -50%);

    z-index: 2;

    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 15px;
    text-align: center;

    line-height: 30px;

    transition: all 0.3s ease-in-out;

    width: 100%;
    padding: 0 20px;
  }

  img {
    height: 101%;
    width: 101%;

    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    object-fit: cover;

    filter: grayscale(100%);

    transition: all 0.3s ease-in-out;
  }

  &:before {
    display: none;
    content: "";

    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    height: 101%;
    width: 101%;

    background: rgba(0, 0, 0, 0.6);
    z-index: 2;

    transition: all 0.6s ease-in-out;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    filter: grayscale(1);
    opacity: 0.5;

    svg {
      position: absolute;
      top: 50%;
      left: -3px;

      transform: translate(0, -50%);

      transition: all 0.8s ease-in-out;
    }
  }

  &:hover {
    img {
      filter: grayscale(0);

      height: 110%;
      width: 110%;
    }

    .name {
      opacity: 0;
    }

    .overlay svg {
      transform: translate(-100%, -50%);
    }

    &:before {
      height: 0;
      /* 
        width: 0;
        border-radius: 50%; 
      */
    }

    .top {
      right: 0;
    }

    .bottom {
      left: 0;
    }
  }

  @media (max-width: 1100px) {
    width: 50%;
    height: 50vw;
  }

  @media (max-width: 650px) {
    width: 100%;
    height: 100vw;
  }
`;

export const Card = styled.a`
  width: 25%;
  height: 25vw;

  display: block;
  float: left;
  position: relative;
  overflow: hidden;

  cursor: pointer;

  .name {
    position: absolute;

    top: 50%;
    left: 0;
    transform: translate(0, -50%);

    z-index: 2;

    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 15px;
    text-align: center;

    line-height: 30px;

    transition: all 0.3s ease-in-out;

    width: 100%;
    padding: 0 20px;
  }

  img {
    height: 101%;
    width: 101%;

    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    object-fit: cover;

    filter: grayscale(100%);

    transition: all 0.3s ease-in-out;
  }

  &:before {
    display: none;
    content: "";

    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    height: 101%;
    width: 101%;

    background: rgba(0, 0, 0, 0.6);
    z-index: 2;

    transition: all 0.6s ease-in-out;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    filter: grayscale(1);
    opacity: 0.5;

    svg {
      position: absolute;
      top: 50%;
      left: -3px;

      transform: translate(0, -50%);

      transition: all 0.8s ease-in-out;
    }
  }

  &:hover {
    img {
      filter: grayscale(0);

      height: 110%;
      width: 110%;
    }

    .name {
      opacity: 0;
    }

    .overlay svg {
      transform: translate(-100%, -50%);
    }

    &:before {
      height: 0;
      /* 
        width: 0;
        border-radius: 50%; 
      */
    }
  }

  @media (max-width: 1100px) {
    width: 50%;
    height: 50vw;
  }

  @media (max-width: 650px) {
    width: 100%;
    height: 100vw;
  }
`;
